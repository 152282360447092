import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { observer } from 'mobx-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styles from './index.module.scss'

type ServiceCard = {
  tag: string
  title: string
  description: string
  imageUrl: string
  linkUrl: string
  linkText: string
}

export const TopPageForStartups2502: FC = observer(() => {
  const { t } = useTranslation(['index'])
  const router = useRouter()

  const services: ServiceCard[] = [
    {
      tag: t('エンジェル投資家と繋がる'),
      title: 'Angel Investor Matching',
      description: t('エンジェル投資家をフィルターで絞り込み検索し、つながることができます。'),
      imageUrl: '/images/common/home/angels.svg',
      linkUrl: '/angels',
      linkText: t('エンジェル投資家一覧へ'),
    },
    {
      tag: t('戦略会議'),
      title: 'Funding Strategy Conference',
      description: t('あなたのビジネスに最適な資金調達方法を見極め、実現可能な具体的アドバイスをご提案。'),
      imageUrl: '/images/common/home/strategy.svg',
      linkUrl: 'https://toshika-lp.protocol.ooo/funding-strategy-conference',
      linkText: t('戦略会議詳細ページへ'),
    },
    {
      tag: t('最適な投資会社の発見'),
      title: 'TOSHIKA DB',
      description: t(
        'アジア全域のVC、CVC、上場企業、非上場企業の中から、あなたに最適な投資家を見つけることができます。'
      ),
      imageUrl: '/images/common/home/toshika-db.svg',
      linkUrl: 'https://invdb.protocol.ooo/',
      linkText: t('TOSHIKA DB詳細ページへ'),
    },
    {
      tag: t('エクイティの資金調達支援'),
      title: 'Fundraising PROTOCOL',
      description: t('エクイティによる資金調達を、事前準備からクロージングまで、ワンストップでサポートいたします。'),
      imageUrl: '/images/common/home/fundraising.svg',
      linkUrl: 'https://toshika-lp.protocol.ooo/toshika',
      linkText: t('Fundraising PROTOCOL詳細ページへ'),
    },
    {
      tag: t('債券流動化'),
      title: 'PROTOCOL IRM',
      description: t('未入金リスクの回避と請求書を即現金化し今までにないキャッシュフローを支援。'),
      imageUrl: '/images/common/home/irm.svg',
      linkUrl: 'https://toshika-lp.protocol.ooo/protocol-irm',
      linkText: t('PROTOCOL IRM詳細ページへ'),
    },
    {
      tag: t('デットの資金調達支援'),
      title: 'PROTOCOL Startup Debt',
      description: t(
        'スタートアップ向けに、財務データだけでなく定性データも活用して与信審査を行い、デットの資金提供を支援。'
      ),
      imageUrl: '/images/common/home/debt.svg',
      linkUrl: 'https://toshika-lp.protocol.ooo/protocol-startup-debt',
      linkText: t('PROTOCOL Startup Debt詳細ページへ'),
    },
    {
      tag: t('M&Aによるイグジットを支援'),
      title: 'PRO M&A',
      description: t('スタートアップのM&Aによるエグジットを、豊富な実績を持つ支援会社と連携してサポートいたします。'),
      imageUrl: '/images/common/home/ma.svg',
      linkUrl: `https://ma.protocol.ooo/${router.locale === 'ja' ? '1' : '1-1'}`,
      linkText: t('PRO M&A詳細ページへ'),
    },
    {
      tag: t('スタートアップの採用支援'),
      title: 'PROTOCOL Job Posting',
      description: t('無料で求人票を公開し人材募集可能。スタートアップの人材採用を加速します。'),
      imageUrl: '/images/common/home/recruit.svg',
      linkUrl: '/jobs',
      linkText: t('無料で求人を公開する'),
    },
    {
      tag: t('スタートアップ向け会員限定特典'),
      title: 'PRO Supporters',
      description: t('スタートアップの成長を支援する優れたサービスを、限定クーポン付きのお得なプランでご紹介します。'),
      imageUrl: '/images/common/home/supporters.svg',
      linkUrl: 'https://prosupporters.protocol.ooo/',
      linkText: t('PRO Supporters詳細ページへ'),
    },
  ]

  return (
    <div className={styles.container}>
      <div className='container'>
        <h1 className={styles.mainTitle}>For Startups</h1>
        <div className={styles.servicesGrid}>
          {services.map((service) => (
            <div key={service.title} className={styles.serviceCard}>
              <span className={styles.tag}># {service.tag}</span>
              <h2 className={styles.title}>{service.title}</h2>
              <div className={styles.imageWrapper}>
                {service.linkUrl.startsWith('http') ? (
                  <a href={service.linkUrl} target='_blank' rel='noopener noreferrer'>
                    <img src={service.imageUrl} alt={service.title} />
                  </a>
                ) : (
                  <Link href={service.linkUrl}>
                    <a>
                      <img src={service.imageUrl} alt={service.title} />
                    </a>
                  </Link>
                )}
              </div>
              <p className={styles.description}>{service.description}</p>
              {service.linkUrl.startsWith('http') ? (
                <a href={service.linkUrl} className={styles.link} target='_blank' rel='noopener noreferrer'>
                  {service.linkText} →
                </a>
              ) : (
                <Link href={service.linkUrl}>
                  <a className={styles.link}>{service.linkText} →</a>
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
})
