import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { observer } from 'mobx-react'
import { useStores } from '@/utils'
import { useRouter } from 'next/router'
import styles from './index.module.scss'

export const TopPageHero2502: FC = observer(() => {
  const { t } = useTranslation(['index'])
  const { viewer } = useStores()
  const router = useRouter()

  return (
    <div className={styles.container}>
      <div className='container'>
        <div className={styles.bgImg} />
        <div className={styles.content}>
          <div className={styles.upper}>
            <div className={styles.newRelease}>
              <span className={styles.badge}>New Release</span>
              <a href='https://invdb.protocol.ooo/' className={styles.text} target='_blank' rel='noopener noreferrer'>
                {t('国内外の1万社以上の投資会社データベース TOSHIKA DB をリリース →')}
              </a>
            </div>
            <h1 className={styles.heading}>Asian Startup Community</h1>
          </div>

          {!viewer.isSignedIn && (
            <div className={styles.bottom}>
              <button
                type='button'
                className={styles.signUpLink}
                onClick={() => {
                  router.push('/signup')
                }}
              >
                {t('無料でアカウントを作成')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})
