import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { observer } from 'mobx-react'
import Link from 'next/link'
import styles from './index.module.scss'

type ServiceCard = {
  tag: string
  title: string
  description: string
  imageUrl: string
  linkUrl: string
  linkText: string
}

export const TopPageForInvestors2502: FC = observer(() => {
  const { t } = useTranslation(['index'])

  const services: ServiceCard[] = [
    {
      tag: t('スタートアップと繋がる'),
      title: 'Startup Matching',
      description: t('スタートアップをフィルターで絞り込んで検索し、つながることができます。'),
      imageUrl: '/images/common/home/matching.svg',
      linkUrl: '/startups',
      linkText: t('スタートアップ一覧へ'),
    },
    {
      tag: t('投資管理クラウド'),
      title: 'Investment Management Cloud',
      description: t('投資案件の探索から投資先の管理まで、一貫して対応可能なクラウドサービスを提供します。'),
      imageUrl: '/images/common/home/management.svg',
      linkUrl: 'https://prosupporters.protocol.ooo/investorcloud',
      linkText: t('投資管理クラウドを無料で開始する'),
    },
    {
      tag: t('厳選スタートアップへの逆オファー制度'),
      title: 'Startup Offer',
      description: t(
        'PROTOCOLが厳選したスタートアップを定期的にご紹介し、スタートアップに対して逆オファーを行えます。'
      ),
      imageUrl: '/images/common/home/opposite.svg',
      linkUrl: 'https://toshika-lp.protocol.ooo/toshika-investor-partners',
      linkText: t('厳選スタートアップの紹介を受け取る'),
    },
  ]

  return (
    <div className={styles.container}>
      <div className='container'>
        <h1 className={styles.mainTitle}>For Investors</h1>
        <div className={styles.servicesGrid}>
          {services.map((service) => (
            <div key={service.title} className={styles.serviceCard}>
              <span className={styles.tag}># {service.tag}</span>
              <h2 className={styles.title}>{service.title}</h2>
              <div className={styles.imageWrapper}>
                {service.linkUrl.startsWith('http') ? (
                  <a href={service.linkUrl} target='_blank' rel='noopener noreferrer'>
                    <img src={service.imageUrl} alt={service.title} />
                  </a>
                ) : (
                  <Link href={service.linkUrl}>
                    <a>
                      <img src={service.imageUrl} alt={service.title} />
                    </a>
                  </Link>
                )}
              </div>
              <p className={styles.description}>{service.description}</p>
              {service.linkUrl.startsWith('http') ? (
                <a href={service.linkUrl} className={styles.link} target='_blank' rel='noopener noreferrer'>
                  {service.linkText} →
                </a>
              ) : (
                <Link href={service.linkUrl}>
                  <a className={styles.link}>{service.linkText} →</a>
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
})
