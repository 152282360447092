import { FC } from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react'
import { IArticle } from '@/types'
import { useTranslation } from 'next-i18next'
import { NoContent } from '@components-v2/atoms/NoContent'
import { getArticleThumbnail, getDateTimeFromUtc } from '@/utils'
import styles from './index.module.scss'

type Props = {
  items: IArticle[]
  category: '特集記事' | 'ストーリー記事'
}

export const TopPageArticles2502: FC<Props> = observer(({ items, category }) => {
  const { t } = useTranslation(['global'])

  const Footer = () => (
    <Link href={category === '特集記事' ? '/articles/categories/features' : '/articles/categories/stories'}>
      <a className={styles.link}>{t(`${category}一覧へ →`)}</a>
    </Link>
  )

  return (
    <div className={styles.container}>
      <div className='container'>
        <h1 className={styles.mainTitle}>{t(`${category}`)}</h1>
        {items?.length === 0 ? (
          <div className={styles.noContent}>
            <NoContent>{t('記事はまだ掲載されていません。')}</NoContent>
          </div>
        ) : (
          <div className={styles.itemList}>
            {items.map((article) => (
              <Link key={article.id} href={`/articles/${article.slug}`}>
                <a className={styles.serviceCard}>
                  <div className={styles.thumbnail}>
                    <img src={getArticleThumbnail(article)} alt={article.title} />
                  </div>
                  <h2 className={styles.title}>{article.title}</h2>
                  <span className={styles.time}>{getDateTimeFromUtc(article.createdAt, 'YYYY.MM.DD')}</span>
                </a>
              </Link>
            ))}
          </div>
        )}
        <Footer />
      </div>
    </div>
  )
})
