import { GetStaticProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { IArticleBase, Language, MessageType } from '@/types'
import { getStaticStores, useStores } from '@/utils'
import { PageWithHeroTemplate } from '@components-v2/templates/PageWithHeroTemplate'
import { TopPageHero2502 } from '@components-v2/organisms/TopPageHero2502'
import { MyHead } from '@components/molecules/MyHead'
import { SignUpPromotionPopup } from '@components/organisms/SignUpPromotionPopup'
import { TopPageConsultingPromotionPopup } from '@components/organisms/TopPageConsultingPromotionPopup'
import { TopPageForStartups2502 } from '@/componentsV2/organisms/TopPageForStartups2502'
import { TopPageForInvestors2502 } from '@/componentsV2/organisms/TopPageForInvestors2502'
import { TopPageArticles2502 } from '@/componentsV2/organisms/TopPageArticles2502'
import styles from './index.module.scss'

type Props = {
  features: IArticleBase[]
  featuredStories: IArticleBase[]
}

const Home: NextPage<Props> = ({ features, featuredStories }) => {
  const { t } = useTranslation(['index'])
  const { messages } = useStores()
  const router = useRouter()

  useEffect(() => {
    // メールアドレス確認リンクを2回クリックした際のクエリパラメータをハンドリングする
    if (router.asPath === '/?error=invalid_confirmation_token') {
      messages.add({
        type: MessageType.Error,
        body: t('メールアドレス確認リンクが間違っています。この症状が続く場合はサポートへお問い合わせください。'),
        isTranslated: true,
        isDismissable: true,
      })
    } else if (router.asPath === '/?error=already_confirmed') {
      messages.add({
        type: MessageType.Info,
        body: t('このメールアドレスは確認済みです。'),
        isTranslated: true,
        isDismissable: true,
      })
    } else if (router.asPath === '/?left_service=true') {
      messages.add({
        type: MessageType.Info,
        body: t('退会しました。'),
        isTranslated: true,
        isDismissable: true,
      })
    }
  }, [])

  return (
    <PageWithHeroTemplate>
      <MyHead title={t('PROTOCOL')} isTitleWithSuffix={false} />
      <div className={styles.container}>
        <TopPageHero2502 />
        <TopPageForStartups2502 />
        <TopPageForInvestors2502 />
        <TopPageArticles2502 items={features.slice(0, 3)} category='特集記事' />
        <TopPageArticles2502 items={featuredStories.slice(0, 3)} category='ストーリー記事' />
      </div>
      <SignUpPromotionPopup />
      <TopPageConsultingPromotionPopup />
    </PageWithHeroTemplate>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const { viewer, articles } = getStaticStores()

  await viewer.changeLanguage(locale as Language)
  const [features, featuredStoriesOutput] = await Promise.all([
    articles.fetchPickedUpFeatures(6),
    articles.fetchFeaturedStories(6),
  ])

  return {
    props: {
      features,
      featuredStories: featuredStoriesOutput.data.featuredStories,
      forcedTheme: 'light',
      ...(await serverSideTranslations(locale, [
        'global',
        'index',
        'jobs',
        'companies-[id]',
        'p',
        'angels-vcs-startups',
      ])),
    },
    revalidate: 30,
  }
}

export default Home
